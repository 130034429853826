<template>
  <div class="application-itmes">
    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
        <b-overlay :show="loading">
          <b-row>
              <!-- business address info -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.business_address')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                            <b-row>
                              <!-- company_name -->
                              <b-col sm="6">
                                  <ValidationProvider name="Company Name (En)" vid="company_name" rules="required">
                                      <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="company_name"
                                      >
                                          <template v-slot:label>
                                              {{ $t('globalTrans.company_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                            v-model="formData.company_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <!-- company_name_bn -->
                              <b-col sm="6">
                                  <ValidationProvider name="Company Name (Bn)" vid="company_name_bn" rules="required">
                                      <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="company_name_bn"
                                      >
                                          <template v-slot:label>
                                              {{ $t('globalTrans.company_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                            v-model="formData.company_name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <!-- business address -->
                              <b-col sm="6">
                                  <ValidationProvider name="Business Address (En)" vid="address_en" rules="">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="address_en"
                                    >
                                      <template v-slot:label>
                                        {{ $t('lrcpnServicePanel.business_address') }} {{ $t('globalTrans.en') }}
                                      </template>
                                      <b-form-textarea
                                        id="address_en"
                                        v-model="formData.address_en"
                                        rows="1"
                                        max-rows="2"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col sm="6">
                                  <ValidationProvider name="Business Address (Bn)" vid="address_bn" rules="required">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="address_bn"
                                    >
                                      <template v-slot:label>
                                        {{ $t('lrcpnServicePanel.business_address') }}  {{ $t('globalTrans.bn') }}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-textarea
                                        id="address_bn"
                                        v-model="formData.address_bn"
                                        rows="1"
                                        max-rows="2"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <!-- business telephone -->
                              <b-col sm="6">
                                <ValidationProvider name="Business Telephone No." vid="telephone_no" rules="required">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="telephone_no"
                                    >
                                        <template v-slot:label>
                                            {{ $t('externalUser.telephone_no') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        v-model="formData.telephone_number"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              <!-- trade license info -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.trade_license_info')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                <!-- trade license no -->
                                  <b-col sm="6">
                                  <ValidationProvider name="Trade License No." vid="trade_license_no" rules="required">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="trade_license_no"
                                    >
                                        <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.trade_license_number') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        v-model="tradeLicenseInfo.trade_license_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                  </b-col>
                                  <!-- trade license type -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Trade License Type" vid="trade_license_type" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="trade_license_type"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.trade_license_type') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="trade_license_type"
                                              v-model="tradeLicenseInfo.trade_license_type"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- trade license issue authority -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Trade License Issuing Authority" vid="trade_license_issuing_authority" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="trade_license_issuing_authority"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.trade_license_provider') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="trade_license_issuing_authority"
                                              v-model="tradeLicenseInfo.trade_license_issuing_authority"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- trade license issue place -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Trade License Issue Place" vid="trade_license_issue_place" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="trade_license_issue_place"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.trade_license_issue_place') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="trade_license_issue_place"
                                              v-model="tradeLicenseInfo.trade_license_issue_place"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- trade license issue date -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Trade Licnese Issue Date" vid="trade_license_issue_date" rules="required">
                                          <b-form-group
                                          class="row"
                                          label-for="trade_license_issue_date"
                                          slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.trade_license_issue_date') }}  <span class="text-danger">*</span>
                                          </template>
                                          <date-picker
                                              id="trade_license_issue_date"
                                              v-model="tradeLicenseInfo.trade_license_issue_date"
                                              class="form-control"
                                              :placeholder="$t('globalTrans.select_date')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              :class="errors[0] ? 'is-invalid' : ''"
                                          >
                                          </date-picker>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- trade license exipre date -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Trade Licnese Expiry Date" vid="trade_license_expiry_date" rules="required">
                                          <b-form-group
                                          class="row"
                                          label-for="trade_license_expiry_date"
                                          slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.trade_license_expire_date') }}  <span class="text-danger">*</span>
                                          </template>
                                          <date-picker
                                              id="trade_license_expiry_date"
                                              v-model="tradeLicenseInfo.trade_license_expiry_date"
                                              class="form-control"
                                              :placeholder="$t('globalTrans.select_date')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              :class="errors[0] ? 'is-invalid' : ''"
                                          >
                                          </date-picker>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              <!-- formalin license info -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.formalin_license_info')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                <!-- trade license no -->
                                  <b-col sm="6">
                                  <ValidationProvider name="Formalin License No." vid="formalin_license_no" rules="required">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="formalin_license_no"
                                    >
                                        <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.formalin_license_no') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        v-model="formalinLicenseInfo.formalin_license_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                  </b-col>
                                  <!-- formalin license issue authority -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Formalin License Issuing Authority" vid="formalin_license_issuing_authority" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="formalin_license_issuing_authority"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.trade_license_provider') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="formalin_license_issuing_authority"
                                              v-model="formalinLicenseInfo.formalin_license_issuing_authority"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- formalin license issue place -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Formalin License Issue Place" vid="formalin_license_issue_place" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="formalin_license_issue_place"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.formalin_license_issue_place') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="formalin_license_issue_place"
                                              v-model="formalinLicenseInfo.formalin_license_issue_place"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- formalin license issue date -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Formalin Licnese Issue Date" vid="formalin_license_issue_date" rules="required">
                                          <b-form-group
                                          class="row"
                                          label-for="formalin_license_issue_date"
                                          slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.formalin_license_issue_date') }}  <span class="text-danger">*</span>
                                          </template>
                                          <date-picker
                                              id="formalin_license_issue_date"
                                              v-model="formalinLicenseInfo.formalin_license_issue_date"
                                              class="form-control"
                                              :placeholder="$t('globalTrans.select_date')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              :class="errors[0] ? 'is-invalid' : ''"
                                          >
                                          </date-picker>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- formalin license exipre date -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Formalin Licnese Expiry Date" vid="formalin_license_expiry_date" rules="required">
                                          <b-form-group
                                          class="row"
                                          label-for="formalin_license_expiry_date"
                                          slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.formalin_license_expiry_date') }}  <span class="text-danger">*</span>
                                          </template>
                                          <date-picker
                                              id="formalin_license_expiry_date"
                                              v-model="formalinLicenseInfo.formalin_license_expiry_date"
                                              class="form-control"
                                              :placeholder="$t('globalTrans.select_date')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              :class="errors[0] ? 'is-invalid' : ''"
                                          >
                                          </date-picker>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              <!-- tax information info -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.tax_information')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                <!-- tin no -->
                                 <b-col sm="6">
                                  <ValidationProvider name="TIN No." vid="trade_license_no" rules="required">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="trade_license_no"
                                    >
                                        <template v-slot:label>
                                            {{ $t('externalUser.tin_no') }}<span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        v-model="taxInfo.tin_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                 </b-col>
                                 <!-- circle -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Circle" vid="circle" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="circle"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.circle') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="circle"
                                              v-model="taxInfo.circle"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- zone -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Zone" vid="zone" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="zone"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.zone') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              id="zone"
                                              v-model="taxInfo.zone"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- tax certificate attachment -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Tax certificate attachment" :rules="attachValidation">
                                      <b-form-group
                                        label-for="tax_certificate_attachment"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.last_two_year_tax_paid_amount') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="tax_certificate_attachment"
                                            v-model="formData.tax_certificate_attachment"
                                            @change="onFileChange($event, 'tax_certificate_attachment')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.tax_certificate_attachment && checkBase64(formData.tax_certificate_attachment) === false" :href="lrcpnServiceBaseUrl  + formData.tax_certificate_attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              <!-- warehouse information -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.warehouse_info')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                  <!-- warehouse address-->
                                  <b-col sm="6">
                                      <ValidationProvider name="Warehouse Address (En)" vid="warehouse_address_en" rules="">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="warehouse_address_en"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.warehouse_address') }} {{ $t('globalTrans.en') }}
                                          </template>
                                          <b-form-textarea
                                            id="warehouse_address_en"
                                            v-model="warehouseInfo.warehouse_address_en"
                                            rows="1"
                                            max-rows="2"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col sm="6">
                                      <ValidationProvider name="Warehouse Address (Bn)" vid="warehouse_address_bn" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="warehouse_address_bn"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.warehouse_address') }}  {{ $t('globalTrans.bn') }}<span class="text-danger">*</span>
                                          </template>
                                          <b-form-textarea
                                            id="warehouse_address_bn"
                                            v-model="warehouseInfo.warehouse_address_bn"
                                            rows="1"
                                            max-rows="2"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- warehouse details -->
                                  <b-col sm="6">
                                      <ValidationProvider name="Warehouse Details" vid="warehouse_details" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="warehouse_details"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.warehouse_details') }}<span class="text-danger">*</span>
                                          </template>
                                          <b-form-textarea
                                            id="warehouse_details"
                                            v-model="warehouseInfo.warehouse_details"
                                            rows="1"
                                            max-rows="2"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- warehouse location -->
                                 <b-col sm="6">
                                      <ValidationProvider name="Warehouse Location" vid="warehouse_location" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="warehouse_location"
                                        >
                                          <template v-slot:label>
                                            {{ $t('lrcpnServicePanel.warehouse_location') }}<span class="text-danger">*</span>
                                          </template>
                                          <b-form-textarea
                                            id="warehouse_location"
                                            v-model="warehouseInfo.warehouse_location"
                                            rows="1"
                                            max-rows="2"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- warehouse capacity -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Warehouse Capacity" vid="warehouse_cpacity" rules="required">
                                      <b-form-group
                                      slot-scope="{ valid, errors }"
                                      label-for="warehouse_cpacity"
                                      >
                                          <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.warehouse_capacity') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          v-model="warehouseInfo.warehouse_cpacity"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              <!-- attachement info -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.attachment_info')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                <!-- rationale_of_demand_attachment -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Rationale" vid="rationale_of_demand_attachment " :rules="attachValidation">
                                      <b-form-group
                                        label-for="rationale_of_demand_attachment"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.rationale_of_demand_attachment') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="rationale_of_demand_attachment"
                                            v-model="formData.rationale_of_demand_attachment"
                                            @change="onFileChange($event, 'rationale_of_demand_attachment')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.rationale_of_demand_attachment && checkBase64(formData.rationale_of_demand_attachment) === false" :href="lrcpnServiceBaseUrl  + formData.rationale_of_demand_attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- previous_import_formalin_attachment -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Previous Import Formalin Attachment " vid="previous_import_formalin_attachment " :rules="attachValidation">
                                      <b-form-group
                                        label-for="previous_import_formalin_attachment"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.previous_import_formalin_attachment') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="previous_import_formalin_attachment"
                                            v-model="formData.previous_import_formalin_attachment"
                                            @change="onFileChange($event, 'previous_import_formalin_attachment')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.previous_import_formalin_attachment && checkBase64(formData.previous_import_formalin_attachment) === false" :href="lrcpnServiceBaseUrl  + formData.previous_import_formalin_attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- Details of LC, Bank Loan etc. in case of import -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Details of LC, Bank Loan etc. in case of import" :rules="attachValidation">
                                      <b-form-group
                                        label-for="bank_lc_details"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.bank_lc_details') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="bank_lc_details"
                                            v-model="formData.bank_lc_details"
                                            @change="onFileChange($event, 'bank_lc_details')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.bank_lc_details && checkBase64(formData.bank_lc_details) === false" :href="lrcpnServiceBaseUrl  + formData.bank_lc_details" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- applicant signature -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Applicant Signature" :rules="attachValidation">
                                      <b-form-group
                                        label-for="applicant_signature"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.applicant_signature') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="applicant_signature"
                                            v-model="formData.applicant_signature"
                                            @change="onFileChange($event, 'applicant_signature')"
                                            accept=".jpg,.png,.jpeg"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.applicant_signature && checkBase64(formData.applicant_signature) === false" :href="lrcpnServiceBaseUrl  + '/storage' + formData.applicant_signature" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              <!-- import info -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.import_details')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                <b-row>
                                  <!-- import_purpose -->
                                  <b-col sm="6">
                                        <ValidationProvider name="Import Purpose" vid="import_purpose" rules="required">
                                          <b-form-group
                                              slot-scope="{ valid, errors }"
                                              label-for="import_purpose"
                                          >
                                            <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.import_purpose') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                              id="import_purpose"
                                              v-model="presentImportDetails.import_purpose"
                                              rows="1"
                                              max-rows="2"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-textarea>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <!-- import_route -->
                                    <b-col sm="6">
                                      <ValidationProvider name="Import Route" vid="import_route" rules="required" >
                                      <b-form-group
                                          slot-scope="{ valid, errors }"
                                          label-for="import_route">
                                          <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.import_route') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          id="import_route"
                                          v-model="presentImportDetails.import_route"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <!-- import_port_name -->
                                    <b-col sm="6">
                                      <ValidationProvider name="Entry Sea/Air/Land-Port" vid="import_port_name" rules="required" >
                                      <b-form-group
                                          slot-scope="{ valid, errors }"
                                          label-for="import_port_name">
                                          <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.import_port_name') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          id="import_port_name"
                                          v-model="presentImportDetails.import_port_name"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <!-- estimated_date_of_import -->
                                    <b-col sm="6">
                                        <ValidationProvider name="Estimated Date Of Import" vid="estimated_date_of_import" rules="required">
                                            <b-form-group
                                            class="row"
                                            label-for="estimated_date_of_import"
                                            slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('lrcpnServicePanel.estimated_date_of_import') }}  <span class="text-danger">*</span>
                                            </template>
                                            <date-picker
                                                id="estimated_date_of_import"
                                                v-model="presentImportDetails.estimated_date_of_import"
                                                class="form-control"
                                                :placeholder="$t('globalTrans.select_date')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :class="errors[0] ? 'is-invalid' : ''"
                                            >
                                            </date-picker>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <!-- have_previous_license -->
                                    <b-col sm="12">
                                      <ValidationProvider name="Already have a license" vid="have_previous_license" rules="required">
                                          <b-form-group
                                            class
                                            label-for="have_previous_license"
                                            slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                            <div style="font-weight: bold">
                                              {{ $t('lrcpnServicePanel.have_previous_license') }}
                                            </div>
                                          </template>
                                          <b-form-radio-group
                                            style="margin-top:5px;"
                                            :id="have_previous_license"
                                            v-model="formData.have_previous_license"
                                            :options="valueOptions"
                                          ></b-form-radio-group>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <!-- previous_license_number -->
                                    <b-col sm="6" v-if="formData.have_previous_license == 1">
                                      <ValidationProvider name="Previous License No." vid="previous_license_number" rules="required">
                                        <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="previous_license_number"
                                        >
                                            <template v-slot:label>
                                                {{ $t('lrcpnServicePanel.previous_license_number') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            v-model="formData.previous_license_info.previous_license_number"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <!-- previous_license_provide_date -->
                                    <b-col sm="6" v-if="formData.have_previous_license == 1">
                                        <ValidationProvider name="Previous License Issue Date" vid="previous_license_provide_date" rules="required">
                                            <b-form-group
                                            class="row"
                                            label-for="previous_license_provide_date"
                                            slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('lrcpnServicePanel.previous_license_provide_date') }}  <span class="text-danger">*</span>
                                            </template>
                                            <date-picker
                                                id="previous_license_provide_date"
                                                v-model="formData.previous_license_info.previous_license_provide_date"
                                                class="form-control"
                                                :placeholder="$t('globalTrans.select_date')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :class="errors[0] ? 'is-invalid' : ''"
                                            >
                                            </date-picker>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <!-- has_previous_license_rejected -->
                                    <b-col sm="6">
                                      <ValidationProvider name="Has the license been canceled before?" vid="has_previous_license_rejected" rules="required">
                                          <b-form-group
                                            class
                                            label-for="has_previous_license_rejected"
                                            slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                            <div style="font-weight: bold">
                                              {{ $t('lrcpnServicePanel.has_previous_license_rejected') }}
                                            </div>
                                          </template>
                                          <b-form-radio-group
                                            style="margin-top:5px;"
                                            :id="has_previous_license_rejected"
                                            v-model="formData.has_previous_license_rejected"
                                            :options="valueOptions"
                                          ></b-form-radio-group>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <!-- previous_license_rejected_reasons -->
                                    <b-col sm="6" v-if="formData.has_previous_license_rejected == 1">
                                        <ValidationProvider name="Rejected Reasons" vid="previous_license_rejected_reasons " rules="required">
                                          <b-form-group
                                              slot-scope="{ valid, errors }"
                                              label-for="previous_license_rejected_reasons "
                                          >
                                            <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.rejected_reasons') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                              id="previous_license_rejected_reasons "
                                              v-model="formData.previous_license_rejected_reasons "
                                              rows="1"
                                              max-rows="2"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-textarea>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <!-- import_helping_agent_name -->
                                    <b-col sm="6">
                                      <ValidationProvider name="Name of indenture/agent assisting in importation" vid="import_helping_agent_name" rules="required" >
                                      <b-form-group
                                          slot-scope="{ valid, errors }"
                                          label-for="import_helping_agent_name">
                                          <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.import_helping_agent_name') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          id="import_helping_agent_name"
                                          v-model="formData.import_helping_agent_name"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <!-- import_helping_agent_address -->
                                    <b-col sm="6">
                                        <ValidationProvider name="Address of indenture/agent assisting in importation" vid="import_helping_agent_address" rules="required">
                                          <b-form-group
                                              slot-scope="{ valid, errors }"
                                              label-for="import_helping_agent_address"
                                          >
                                            <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.import_helping_agent_address') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                              id="import_helping_agent_address"
                                              v-model="formData.import_helping_agent_address"
                                              rows="1"
                                              max-rows="2"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-textarea>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <!-- have_any_case_formalin_act_2015 -->
                                    <b-col sm="12">
                                      <ValidationProvider name="Have you been punished under the Formalin Control Act, 2015" vid="have_any_case_formalin_act_2015" rules="required">
                                          <b-form-group
                                            class
                                            label-for="have_any_case_formalin_act_2015"
                                            slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                            <div style="font-weight: bold">
                                              {{ $t('lrcpnServicePanel.have_any_case_formalin_act_2015') }}
                                            </div>
                                          </template>
                                          <b-form-radio-group
                                            style="margin-top:5px;"
                                            :id="have_any_case_formalin_act_2015"
                                            v-model="formData.have_any_case_formalin_act_2015"
                                            :options="valueOptions"
                                          ></b-form-radio-group>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <!-- case_no_and_section -->
                                    <b-col sm="6" v-if="formData.have_any_case_formalin_act_2015 == 1">
                                        <ValidationProvider name="Case No. and Section" vid="case_no_and_section " rules="required">
                                          <b-form-group
                                              slot-scope="{ valid, errors }"
                                              label-for="case_no_and_section "
                                          >
                                            <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.case_no_and_section') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                              id="case_no_and_section "
                                              v-model="formData.case_info.case_no_and_section "
                                              rows="1"
                                              max-rows="2"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-textarea>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <!-- date_of_case_judgment -->
                                    <b-col sm="6" v-if="formData.have_any_case_formalin_act_2015 == 1">
                                        <ValidationProvider name="Date of Case Judgment" vid="date_of_case_judgment" rules="required">
                                            <b-form-group
                                            class="row"
                                            label-for="date_of_case_judgment"
                                            slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('lrcpnServicePanel.date_of_case_judgment') }}  <span class="text-danger">*</span>
                                            </template>
                                            <date-picker
                                                id="date_of_case_judgment"
                                                v-model="formData.case_info.date_of_case_judgment"
                                                class="form-control"
                                                :placeholder="$t('globalTrans.select_date')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :class="errors[0] ? 'is-invalid' : ''"
                                            >
                                            </date-picker>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <!-- date_of_completion_of_sentence -->
                                    <b-col sm="6" v-if="formData.have_any_case_formalin_act_2015 == 1">
                                        <ValidationProvider name="Date of Completion of Penalty" vid="date_of_completion_of_sentence" rules="required">
                                            <b-form-group
                                            class="row"
                                            label-for="date_of_completion_of_sentence"
                                            slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('lrcpnServicePanel.date_of_completion_of_sentence') }}  <span class="text-danger">*</span>
                                            </template>
                                            <date-picker
                                                id="date_of_completion_of_sentence"
                                                v-model="formData.case_info.date_of_completion_of_sentence"
                                                class="form-control"
                                                :placeholder="$t('globalTrans.select_date')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :class="errors[0] ? 'is-invalid' : ''"
                                            >
                                            </date-picker>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <!-- aditional_information -->
                                    <b-col sm="12">
                                        <ValidationProvider name="Case No. and Section" vid="aditional_information " rules="required">
                                          <b-form-group
                                              slot-scope="{ valid, errors }"
                                              label-for="aditional_information "
                                          >
                                            <template v-slot:label>
                                              {{ $t('lrcpnServicePanel.aditional_information') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                              id="aditional_information "
                                              v-model="formData.aditional_information "
                                              rows="1"
                                              max-rows="2"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-textarea>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
          </b-row>
        </b-overlay>
      </b-form>
    </ValidationObserver>
    <!-- rest of import infomariton -->
    <b-col sm="12">
        <div class="group-form-card">
            <b-card>
                <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.import_info')" label-size="lg"
                    label-class="font-weight-bold pt-0" class="mb-0">
                    <b-row>
                      <ValidationObserver ref="add_more_data" v-slot="{ handaleAddMoreData, reset }">
                        <b-form @submit.prevent="handaleAddMoreData(addMore)" @reset.prevent="reset">
                            <b-row>
                              <div>
                                  <!-- add more -->
                              <b-row>
                                  <!-- row_material_name -->
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                      <ValidationProvider name="Row Material Name (En)" vid="row_material_name_en" rules="required" >
                                      <b-form-group
                                          slot-scope="{ valid, errors }"
                                          label-for="row_material_name_en">
                                          <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.row_material_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          id="row_material_name_en"
                                          v-model="addMoreData.row_material_name_en"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- Material bn -->
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                      <ValidationProvider name="Row Material Name (Bn)" vid="row_material_name_bn" rules="required">
                                      <b-form-group
                                          slot-scope="{ valid, errors }"
                                          label-for="row_material_name_bn">
                                          <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.row_material_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          id="row_material_name_bn"
                                          v-model="addMoreData.row_material_name_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- Quantity  -->
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                      <ValidationProvider name="Quantity" vid="qty" :rules="{required:true, min_value: 1}">
                                      <b-form-group
                                          slot-scope="{ valid, errors }"
                                          label-for="qty">
                                          <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.qty') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          @keypress="isNumber"
                                          id="qty"
                                          v-model.number="addMoreData.qty"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <!-- Price -->
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                      <ValidationProvider name="Price" vid="price" :rules="{required:true, min_value: 1}" >
                                      <b-form-group
                                          slot-scope="{ valid, errors }"
                                          label-for="price">
                                          <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.price') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          @keypress="isNumber"
                                          id="price"
                                          v-model.number="addMoreData.price"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                              </b-row>
                              <div class="d-flex justify-content-end">
                                  <b-button type="button" v-if="is_edit" variant="danger" class="mr-1 btn-sm" @click="cancelItem()">{{ $t('globalTrans.cancel') }}</b-button>
                                  <b-button type="button" @click="addMore" variant="success" class="mr-2 btn-sm">{{ is_edit ? $t('globalTrans.update') : $t('globalTrans.add_more') }}</b-button>
                              </div>
                              <hr>
                              <!-- table data show -->
                              <b-overlay :show="addMoreLoading">
                              <b-table-simple style="width: 100%" bordered>
                                  <b-tr>
                                      <b-th class="text-center" style="width: 50%;"> {{ $t('lrcpnServicePanel.row_material_name') }}</b-th>
                                      <b-th class="text-center" style="width: 15%;"> {{ $t('lrcpnServicePanel.qty') }}</b-th>
                                      <b-th class="text-center" style="width: 15%;"> {{ $t('lrcpnServicePanel.price') }}</b-th>
                                      <b-th class="text-center" style="width: 20%;"> {{ $t('globalTrans.action') }}</b-th>
                                  </b-tr>
                                  <template v-if="formData.import_amounts_info.length">
                                      <slot v-for="(item, index) in formData.import_amounts_info">
                                          <b-tr :key="index">
                                              <b-td class="text-center">
                                                  {{ currentLocale === 'en' ? item.row_material_name_en : item.row_material_name_bn }}
                                              </b-td>
                                              <b-td class="text-center">
                                                  {{ $n(item.qty, {useGrouping: false}) }}
                                              </b-td>
                                              <b-td class="text-center">
                                                  {{ $n(item.price, {useGrouping: false}) }}
                                              </b-td>
                                              <b-td class="text-center">
                                                  <b-button @click="editAddMore(index, item)" class="btn btn-sm btn-info"><i class="ri-edit-box-line"></i></b-button>
                                                  <b-button type="button" @click="removeAddMore(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                                              </b-td>
                                          </b-tr>
                                      </slot>
                                      <b-tr>
                                          <b-td colspan="2" class="text-right">{{$t('globalTrans.total')}}</b-td>
                                          <b-td class="text-center">{{ $n(totalAmount) }}</b-td>
                                      </b-tr>
                                  </template>
                                  <template v-else>
                                      <b-tr>
                                          <b-td colspan="4" class="text-center">{{ $t('national_award.no_data_added') }}</b-td>
                                      </b-tr>
                                  </template>
                              </b-table-simple>
                              <div v-if="importAmountError" class="text-center">
                                  <b class="text-danger">Please Insert at least One Data</b>
                              </div>
                              </b-overlay>
                              </div>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                    </b-row>
                </b-form-group>
            </b-card>
        </div>
    </b-col>
  </div>
</template>

<script>
import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
import { clearanceBusinessInfoStore } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
export default {
props: ['tabFormData', 'status', 'isRenew'],
components: { },
data () {
  return {
    valid: null,
    attachValidation: 'required',
    formData: this.tabFormData,
    lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
    tradeLicenseInfo: this.tabFormData.trade_license_info,
    formalinLicenseInfo: this.tabFormData.formalin_license_info,
    taxInfo: this.tabFormData.tax_info,
    warehouseInfo: this.tabFormData.warehouse_details,
    presentImportDetails: this.tabFormData.present_import_details,
    importAmountError: false,
    addMoreData: {
      row_material_name_en: '',
      row_material_name_bn: '',
      qty: 0,
      price: 0
    },
    addMoreLoading: false,
    is_edit: false,
    valueOptions: [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Yes' : 'হ্যাঁ' },
        { value: 0, text: this.$i18n.locale === 'en' ? 'No' : 'না' }
    ]
  }
},
created () {
  if (this.$route.params.id && !this.isRenew) {
    this.attachValidation = ''
  }
},
mounted () {
  this.formData = this.tabFormData
},
computed: {
  loading: function () {
    return this.$store.state.commonObj.loading
  },
  currentLocale () {
    return this.$i18n.locale
  },
  totalAmount () {
      let total = 0
      this.formData.import_amounts_info.forEach(item => {
          total += item.price
      })
      return total
  }
},
watch: {
},
methods: {
  onFileChange (event, property) {
    const input = event.target
    if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
            this.formData[property] = e.target.result
        }
        reader.readAsDataURL(input.files[0])
    } else {
        this.formData[property] = ''
    }
  },
    checkBase64 (string) {
      var result = ''
      result = string.match('data:')
      if (result) {
          return true
      } else {
          return false
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    async addMore () {
        this.addMoreLoading = true
        var check = await this.$refs.add_more_data.validate()
        if (check) {
            if (this.is_edit) {
                this.formData.import_amounts_info.splice(this.addMoreData.index, 1, this.addMoreData)
            } else {
                this.formData.import_amounts_info.push(this.addMoreData)
            }
            this.addMoreData = {}
            this.$refs.add_more_data.reset()
        }
        this.is_edit = false

        this.checkImportAmount()

        setTimeout(() => {
            this.addMoreLoading = false
        }, 100)
    },
    removeAddMore (index) {
        this.formData.import_amounts_info.splice(index, 1)
        this.checkImportAmount()
    },
    editAddMore (index, item) {
        this.addMoreData = Object.assign({}, item)
        this.is_edit = true
        this.addMoreData.index = index
    },
    cancelItem () {
      this.addMoreData = {}
      this.is_edit = false
      this.$refs.add_more_data.reset()
    },
    checkImportAmount () {
      if (this.formData.import_amounts_info.length === 0) {
          this.importAmountError = true
        } else {
          this.importAmountError = false
        }
    },
  async submit () {
    var check = await this.$refs.form.validate()
    this.checkImportAmount()
    if (check && this.importAmountError === false) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      const data = Object.assign({}, this.formData, { status: this.status })
      const result = await RestApi.postData(lrcpnServiceBaseUrl, clearanceBusinessInfoStore, data)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.formData.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        return result
      } else {
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: this.$t('globalTrans.form_error_msg'),
          color: '#ee5253'
        })
      }
    }
  }
}
}
</script>
