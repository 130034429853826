<template>
    <div class="application-itmes">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                <b-overlay :show="loading">
                    <b-row>
                        <b-col sm="12">
                            <div class="group-form-card">
                                <b-card>
                                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.applicant_info')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <b-row>
                                            <!-- search for admin -->
                                            <b-col sm="12" v-if="authUser.user_type === 1">
                                                <div class="row">
                                                    <b-col sm="6">
                                                        <ValidationProvider name="Telephone Number" vid="telephone_number" :rules="'max:11|min:11|numeric'">
                                                            <b-form-group
                                                                slot-scope="{ valid, errors }"
                                                                label-for="telephone_number">
                                                                <template v-slot:label>
                                                                    {{ $t('externalUser.telephone_no') }}
                                                                </template>
                                                                <b-form-input
                                                                    @keypress="isNumber"
                                                                    type="text"
                                                                    v-model="search.mobile"
                                                                    :state="errors[0] ? false : (valid ? true : null)">
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="6">
                                                        <b-button type="button" @click="getExternalUserInfo()" variant="success" class="mt-3 btn-sm">{{ $t('globalTrans.search') }} <b-spinner v-if="isExternalUserLoading" variant="danger" small label="Spinning"></b-spinner></b-button>
                                                    </b-col>
                                                </div>
                                            </b-col>
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Name (En)" vid="applicant_name_en" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="applicant_name_en"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.applicant_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.applicant_name_en"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          :disabled="isExternalUserFind"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Name (Bn)" vid="applicant_name_bn" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="applicant_name_bn"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.applicant_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.applicant_name_bn"
                                                          :disabled="isExternalUserFind"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Father's/Husband Name (En)" vid="father_spouse_name" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="father_spouse_name"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('lrcpnServicePanel.father_spouse_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.father_spouse_name_en"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          :disabled="isRenew"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Father's/Husband Name (Bn)" vid="designation_bn" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="father_spouse_name_bn"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('lrcpnServicePanel.father_spouse_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.father_spouse_name_bn"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          :disabled="isRenew"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Mother's Name (En)" vid="mother_name" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="mother_name"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.mother_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.mother_name_en"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          :disabled="isRenew"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Mother's Name (Bn)" vid="mother_name_bn" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="mother_name_bn"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.mother_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.mother_name_bn"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          :disabled="isRenew"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant NID" vid="applicant_nid" :rules="'max:'+(invalidNid ? 0 : (10|13|17))+'|min:10|numeric|required'">
                                                    <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="applicant_nid"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.nid') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        v-model="formData.applicant_nid"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        @input="nidRules($event)"
                                                        :disabled="isRenew"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @keypress="isNumber"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Passport No." vid="passport_no" rules="">
                                                    <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="passport_no"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('externalUser.passport_no') }}
                                                        </template>
                                                        <b-form-input
                                                        v-model="formData.passport_no"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Telephone No." vid="telephone_no" rules="required">
                                                    <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="telephone_no"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('externalUser.telephone_no') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        v-model="formData.telephone_number"
                                                        :disabled="isExternalUserFind"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @keypress="isNumber"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Nationality" vid="applicant_nationality" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="applicant_nationality"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.nationality') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.nationality"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          :disabled="isRenew"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </b-card>
                            </div>
                        </b-col>
                        <b-col sm="12">
                            <div class="group-form-card">
                                <b-card>
                                    <b-form-group label-cols-lg="3" :label="$t('globalTrans.permanent_address')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <Address :key="address.area_type_id" :data.sync="address" :addressType="'address'" :param="fieldObj" @address-data="receiveAddress" title="Permanent Address" />
                                    </b-form-group>
                                </b-card>
                            </div>
                        </b-col>
                        <b-col sm="12">
                            <div class="group-form-card">
                                <b-card>
                                    <b-form-group label-cols-lg="3" :label="$t('globalTrans.present_address')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <b-col sm="12">
                                            <b-form-checkbox
                                                id="same_as_permanent"
                                                class="witness-agree-wrapper"
                                                size="lg"
                                                v-model="formData.same_as_permanent"
                                                name="same_as_permanent"
                                            ><b>{{ $t('lrcpnServicePanel.same_as_permanent') }}</b></b-form-checkbox>
                                        </b-col>
                                        <Address :key="presentAddress.area_type_id" :data.sync="presentAddress" :addressType="'address'" :param="fieldObj" @address-data="receiveAddress2" title="Present Address"/>
                                    </b-form-group>
                                </b-card>
                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-form>
        </ValidationObserver>
    </div>
</template>

<script>
import RestApi, { lrcpnServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { clearanceApplicantInfoStore, clearanceApplicantInfoUpdate } from '../../api/routes'
import Address from '@/components/Address.vue'
import { helpers } from '@/utils/helper-functions'
import { mapGetters } from 'vuex'
export default {
    props: ['tabFormData', 'status', 'parentId', 'isRenew'],
    components: { Address },
    data () {
        return {
            lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
            valid: null,
            invalidNid: 0,
            isExternalUserLoading: false,
            search: {
                mobile: ''
            },
            fieldObj: {
                xl: 6,
                lg: 6,
                md: 6,
                sm: 12,
                labelCols: 12,
                noCountry: true,
                noAddress: false,
                isPaurashavaNullable: true,
                isUnionNullable: true,
                hasPostCode: false,
                hasWardShow: false,
                isPostCodeNullable: true,
                noMargin: true
            },
            formData: {},
            address: {
              area_type_id: 0,
              division_id: 0,
              district_id: 0,
              city_corporation_id: 0,
              paurashava_id: 0,
              upazila_id: 0,
              union_id: 0,
              ward_id: 0,
              address_details_en: '',
              address_details_bn: ''
            },
            presentAddress: {
              area_type_id: 0,
              division_id: 0,
              district_id: 0,
              city_corporation_id: 0,
              paurashava_id: 0,
              upazila_id: 0,
              union_id: 0,
              ward_id: 0,
              address_details_en: '',
              address_details_bn: ''
            }
        }
    },
    created () {
    },
    mounted () {
        this.formData = this.tabFormData
        this.address = {
            area_type_id: this.formData.permanent_area_type_id,
            division_id: this.formData.permanent_division_id,
            district_id: this.formData.permanent_district_id,
            city_corporation_id: this.formData.permanent_city_corporation_id,
            paurashava_id: this.formData.permanent_pauroshoba_id,
            upazila_id: this.formData.permanent_upazila_id,
            union_id: this.formData.permanent_union_id,
            address_details_en: this.formData.permanent_village_name_en,
            address_details_bn: this.formData.permanent_village_name_bn
        }
        this.$set(this.presentAddress, 'area_type_id', this.tabFormData.present_area_type_id)
        this.$set(this.presentAddress, 'division_id', this.tabFormData.present_division_id)
        this.$set(this.presentAddress, 'district_id', this.tabFormData.present_district_id)
        this.$set(this.presentAddress, 'city_corporation_id', this.tabFormData.present_city_corporation_id)
        this.$set(this.presentAddress, 'paurashava_id', this.tabFormData.present_pauroshoba_id)
        this.$set(this.presentAddress, 'upazila_id', this.tabFormData.present_upazila_id)
        this.$set(this.presentAddress, 'union_id', this.tabFormData.present_city_corporation_id)
        this.$set(this.presentAddress, 'address_details_en', this.tabFormData.present_village_name_en)
        this.$set(this.presentAddress, 'address_details_bn', this.tabFormData.present_village_name_bn)
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        },
        yesNoList () {
            return [
                { text: this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ', value: 1, text_en: 'Yes', text_bn: 'হ্যাঁ' },
                { text: this.currentLocale === 'en' ? 'No' : 'না', value: 2, text_en: 'No', text_bn: 'না' }
            ]
        },
        fiscalYearList () {
            return this.$store.state.CommonService.commonObj.fiscalYearList
        }
    },
    watch: {
        'formData.same_as_permanent': function (newVal, oldValue) {
            if (newVal !== oldValue) {
              this.setPresentasPermanent(newVal)
            }
        }
    },
    methods: {
        setPresentasPermanent (value) {
            if (value) {
                // present address
                this.$set(this.presentAddress, 'area_type_id', this.address.area_type_id)
                this.$set(this.presentAddress, 'division_id', this.address.division_id)
                this.$set(this.presentAddress, 'district_id', this.address.district_id)
                this.$set(this.presentAddress, 'city_corporation_id', this.address.city_corporation_id)
                this.$set(this.presentAddress, 'paurashava_id', this.address.paurashava_id)
                this.$set(this.presentAddress, 'upazila_id', this.address.upazila_id)
                this.$set(this.presentAddress, 'union_id', this.address.city_corporation_id)
                this.$set(this.presentAddress, 'address_details_en', this.address.address_details_en)
                this.$set(this.presentAddress, 'address_details_bn', this.address.address_details_bn)
            } else {
                // present address
                if (this.formData.id < 0) {
                    this.$set(this.presentAddress, 'area_type_id', 0)
                    this.$set(this.presentAddress, 'division_id', 0)
                    this.$set(this.presentAddress, 'district_id', 0)
                    this.$set(this.presentAddress, 'city_corporation_id', 0)
                    this.$set(this.presentAddress, 'paurashava_id', 0)
                    this.$set(this.presentAddress, 'upazila_id', 0)
                    this.$set(this.presentAddress, 'union_id', 0)
                    this.$set(this.presentAddress, 'address_details_en', '')
                    this.$set(this.presentAddress, 'address_details_bn', '')
                }
            }
        },
        nidRules (e) {
            if (e.length) {
                this.invalidNid = (parseInt(e.length) === 10 || parseInt(e.length) === 13 || parseInt(e.length) === 17) ? 0 : 1
            } else {
                this.invalidNid = 1
            }
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }
                let result
                const data = Object.assign({}, this.formData, { status: this.status, parent_id: this.parentId })
                if (this.formData.id && !this.isRenew) {
                  result = await RestApi.putData(lrcpnServiceBaseUrl, clearanceApplicantInfoUpdate + '/' + this.formData.id, data)
                } else {
                  result = await RestApi.postData(lrcpnServiceBaseUrl, clearanceApplicantInfoStore, data)
                }
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.formData.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$refs.form.setErrors(result.errors)
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                    })
                }
            }
        },
        receiveAddress (address) {
            this.address = address
            this.formData = Object.assign({}, this.formData, {
              permanent_area_type_id: this.address.area_type_id,
              permanent_division_id: this.address.division_id,
              permanent_district_id: this.address.district_id,
              permanent_city_corporation_id: this.address.city_corporation_id,
              permanent_pauroshoba_id: this.address.paurashava_id,
              permanent_upazila_id: this.address.upazila_id,
              permanent_union_id: this.address.union_id,
              permanent_village_name_en: this.address.address_details_en,
              permanent_village_name_bn: this.address.address_details_bn
            })
        },
        receiveAddress2 (address) {
            this.presentAddress = address
            this.formData = Object.assign({}, this.formData, {
              present_area_type_id: this.address.area_type_id,
              present_division_id: this.address.division_id,
              present_district_id: this.address.district_id,
              present_city_corporation_id: this.address.city_corporation_id,
              present_pauroshoba_id: this.address.paurashava_id,
              present_upazila_id: this.address.upazila_id,
              present_union_id: this.address.union_id,
              present_village_name_en: this.address.address_details_en,
              present_village_name_bn: this.address.address_details_bn
            })
        },
        receiveAddMoreAddress (address) {
            this.formDataAddMore.address = address
        },
        isNumber (event) {
            helpers.isNumber(event)
        },
        async getExternalUserInfo () {
            this.isExternalUserLoading = true
            var result
            result = await RestApi.postData(authServiceBaseUrl, 'user-management/user/get-user-details', this.search)
            if (result.success) {
                const data = {
                    applicant_name_en: result.data.name,
                    applicant_name_bn: result.data.name_bn,
                    telephone_number: result.data.mobile,
                    applicant_email: result.data.email,
                    user_id: result.data.id
                }
                this.formData = Object.assign({}, this.formData, data)
                this.isExternalUserFind = true
            } else {
                const data = {
                    applicant_name_en: '',
                    applicant_name_bn: '',
                    telephone_number: '',
                    applicant_email: '',
                    user_id: null
                }
                this.formData = Object.assign({}, this.formData, data)
                this.isExternalUserFind = false
            }
            this.isExternalUserLoading = false
        }
    }
}
</script>
